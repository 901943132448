import * as React from "react";
import { SVGProps } from "react";

const SvgReset = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M6.717 16.592a7 7 0 1 0 .69-9.875" />
      <path d="M10.188 18.761a7 7 0 1 0-4.95-8.573" />
      <path d="M4 6.738v4h4.059" />
    </g>
  </svg>
);

export default SvgReset;

import * as React from "react";
import { SVGProps } from "react";

const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path strokeLinecap="round" d="m2 10 10-4 10 4-10 4z" />
      <path d="M6 12v4l6 2 6-2v-4" />
      <path strokeLinecap="round" d="M3 10v5" />
    </g>
  </svg>
);

export default SvgEducation;

import * as React from "react";
import { SVGProps } from "react";

const SvgBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="m14.926 1.07 1.691 2.928h3.381V7.38l2.928 1.691-1.691 2.928 1.691 2.928-2.928 1.691v3.381h-3.381l-1.69 2.928-2.929-1.691-2.928 1.691-1.691-2.928h-3.38l-.001-3.381-2.928-1.69 1.691-2.929L1.07 9.07l2.928-1.691v-3.38l3.381-.001L9.07 1.07l2.928 1.691 2.928-1.691Z"
        fill="#F8D030"
        fillRule="nonzero"
      />
      <path
        d="m15.998 8.998-5.333 6-2.667-3"
        stroke="#0F274F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export default SvgBadge;

import React, { useState } from "react";

import { Label, LabelsWrapper, Range, TickMark, TickMarks, Wrapper } from "./components";

export interface Option {
  value: number | string;
  topLabel?: string;
  bottomLabel?: string;
}

export interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement>, SC {
  options: Option[];
  initialValueIndex?: number;
}

const Slider: React.FC<SliderProps> = ({
  options,
  disabled = false,
  initialValueIndex = 0,
  onChange,
  min,
  max,
  step,
  value,
  className,
  type = "range",
  ...props
}: SliderProps) => {
  const steps: number = options?.length || 1;
  const [valueIndex, setValueIndex] = useState(initialValueIndex);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const indexOfValue = parseInt(e.target.value);
    setValueIndex(indexOfValue);
    const newEvent = {
      ...e,
      target: {
        ...e.target,
        value: options[indexOfValue].value.toString(),
      },
    };
    onChange && onChange(newEvent);
  };

  return (
    <Wrapper className={className}>
      <LabelsWrapper>
        {options?.map((option, index) => (
          <Label selected={index === valueIndex} key={option.value}>
            {option.topLabel}
          </Label>
        ))}
      </LabelsWrapper>
      <Range
        min={0}
        max={steps - 1}
        step={1}
        onChange={handleChange}
        value={valueIndex}
        disabled={disabled}
        {...props}
      />
      <TickMarks>
        {options.map((option, index) => (
          <TickMark key={option.value} isCurrent={index === valueIndex} lessThanCurrent={index < valueIndex} />
        ))}
      </TickMarks>
      <LabelsWrapper>
        {options?.map((option, index) => (
          <Label selected={index === valueIndex} key={option.value}>
            {option.bottomLabel}
          </Label>
        ))}
      </LabelsWrapper>
    </Wrapper>
  );
};
export default Slider;

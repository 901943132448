import * as React from "react";
import { SVGProps } from "react";

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path strokeLinejoin="round" d="M5 6h14l-1 15H6zM9 3h6v3H9z" />
      <path d="M3 6h18" />
    </g>
  </svg>
);

export default SvgTrash;

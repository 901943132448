import * as React from "react";
import { SVGProps } from "react";

const SvgAvalancheLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.401 4.117H4.583v13.468H19.4V4.117Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.992 12c0 6.619-5.369 11.984-11.992 11.984C5.377 23.984.008 18.62.008 12 .008 5.382 5.377.016 12 .016 18.623.016 23.992 5.382 23.992 12Zm-15.39 4.77H6.274c-.489 0-.73 0-.877-.095a.59.59 0 0 1-.268-.462c-.01-.174.111-.386.353-.81l5.747-10.122c.244-.43.368-.645.524-.724a.593.593 0 0 1 .536 0c.156.08.28.294.524.724l1.182 2.06.006.011c.264.461.398.695.456.94.065.269.065.551 0 .82-.059.246-.191.482-.46.95l-3.018 5.332-.008.014c-.265.465-.4.7-.587.878a1.756 1.756 0 0 1-.716.415c-.244.068-.518.068-1.066.068Zm5.877 0h3.335c.492 0 .74 0 .887-.098a.59.59 0 0 0 .268-.465c.008-.168-.11-.372-.341-.771l-.025-.042-1.67-2.855-.019-.032c-.235-.397-.353-.597-.505-.675a.587.587 0 0 0-.534 0c-.153.08-.277.289-.521.71l-1.664 2.855-.006.01c-.244.42-.366.63-.357.803a.596.596 0 0 0 .268.465c.145.094.392.094.884.094Z"
      fill="#E84142"
    />
  </svg>
);

export default SvgAvalancheLogo;

import React from "react";
import styled from "styled-components";

import SegmentLabel, { SegmentProps } from "../index";
import { SegmentContainer as _SegmentContainer } from "../styles";

const Wrapper = styled.div`
  ${_SegmentContainer}
`;

interface Option {
  label: string;
  value: string;
}

export interface SegmentedControlProps extends SegmentProps, SC {
  options?: Record<keyof Option, string>[];
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  name,
  value,
  options = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ],
  onChange,
  className,
  disabled,
  ...props
}) => {
  return (
    <Wrapper className={className}>
      {options.map((item) => (
        <SegmentLabel
          key={item.value}
          children={item.label}
          name={name}
          checked={item.value === value}
          value={item.value}
          disabled={disabled}
          onChange={onChange}
          {...props}
        ></SegmentLabel>
      ))}
    </Wrapper>
  );
};

export default SegmentedControl;

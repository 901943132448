import styled from "styled-components";

export const Wrapper = styled.div``;

export const _AccordionItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`};
  padding: 0 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 24px 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 0;
`;

export const AccordionDetails = styled.div<{ expanded: boolean }>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  padding: 0 16px;
  ${({ expanded }) => expanded && `max-height: 10000px; visibility: visible;`};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

export const AccordionContent = styled.div`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.palette.white.main};
`;

export const Icon = styled.div<{ expanded: boolean }>`
  transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
  transition: all 0.5s ease-in-out;
  color: ${({ theme }) => theme.palette.white.main};
  align-self: start;

  svg {
    path,
    g {
      stroke: currentColor;
    }
  }
`;

export const Bold = styled.b`
  font-weight: 500;
`;

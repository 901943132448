import * as React from "react";
import { SVGProps } from "react";

const SvgMarriage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(3 3)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={6} cy={9} r={6} />
      <circle cx={12} cy={9} r={6} />
      <path d="m4.5 3-.776-1.553A1 1 0 0 1 4.618 0h2.764a1 1 0 0 1 .894 1.447L7.5 3h0" />
    </g>
  </svg>
);

export default SvgMarriage;

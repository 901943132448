import React, { useState } from "react";
import styled from "styled-components";

import { convertTint } from "../../../utils/theme";
import { Container as _Container, Input as _Input, Label as _Label } from "./styles";

const Label = styled.label<{ active?: boolean; disabled?: boolean }>`
  ${_Label}
`;

const Container = styled.div`
  ${_Container}

  &:focus-within ${Label} {
    transform: translateY(8px) scale(0.77);
  }
`;

const Input = styled.input`
  ${_Input}
`;

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, SC {
  label: string;
  tint: Tint;
  valid?: boolean;
  error?: string;
}

const RectangleInput: React.FC<InputProps> = React.forwardRef<HTMLDivElement, InputProps>(
  ({ label, value, tint, error, disabled, placeholder, className, ...props }: InputProps, ref) => {
    const [currentPlaceholder, setCurrentPlaceholder] = useState("");
    const color = convertTint(tint);

    return (
      <div className={className} ref={ref}>
        <Container tint={color} error={!!error} disabled={disabled}>
          <Label active={!!value || !!currentPlaceholder} disabled={disabled}>
            {label}
          </Label>
          <Input
            {...props}
            value={value}
            placeholder={currentPlaceholder}
            onFocus={() => setCurrentPlaceholder(placeholder || "")}
            onBlur={() => setCurrentPlaceholder("")}
            disabled={disabled}
          />
        </Container>
      </div>
    );
  },
);

export default RectangleInput;

import React from 'react'
import styled from 'styled-components'

import { convertTint } from '../../../utils/theme'
import { ButtonProps } from '../index'
import { Button as _PrimaryButton } from '../Primary/styles'
import { Button as _SecondaryButton, IconWrapper as _IconWrapper } from './styles'

const Button = styled.button`
  ${_PrimaryButton}
  ${_SecondaryButton}
`

const IconWrapper = styled.div`
  ${_IconWrapper}

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
`

const SecondaryButton: React.FC<ButtonProps> = ({
  tint = 'mint',
  align = 'center',
  icon,
  iconPosition = 'right',
  children,
  ...props
}: ButtonProps) => {
  const color = convertTint(tint)
  return (
    <Button tint={color} align={align} {...props}>
      {icon && iconPosition === 'left' && <IconWrapper>{icon}</IconWrapper>}
      <span>{children}</span>
      {icon && iconPosition === 'right' && <IconWrapper>{icon}</IconWrapper>}
    </Button>
  )
}

export default SecondaryButton

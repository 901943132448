import * as React from 'react'
import { SVGProps } from 'react'

const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 12a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0ZM12 3.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5Zm1.643 4.136a.75.75 0 1 0-1.286-.772l-3 5A.75.75 0 0 0 10 12.75h2.675l-2.318 3.864a.75.75 0 1 0 1.286.772l3-5A.75.75 0 0 0 14 11.25h-2.675l2.318-3.864Z"
      fill="#F4F4F4"
    />
  </svg>
)

export default SvgNotification

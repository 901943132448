import React, { useState } from "react";
import styled from "styled-components";

import { ErrorMessage } from "../../Typography";
import { Container as _Container, Input as _Input, Label as _Label } from "./styles";

const Label = styled.label`
  ${_Label}
`;

const Input = styled.input`
  ${_Input}
`;

const Container = styled.div<{ error?: boolean; disabled?: boolean }>`
  ${_Container}

  &:focus-within ${Label} {
    ${({ disabled }) => !disabled && `transform: translateY(8px) scale(0.77)`};
  }
`;

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, SC {
  label: string;
  valid?: boolean;
  error?: string;
}

const InputField: React.FC<InputProps> = ({
  label,
  value,
  error,
  disabled,
  placeholder,
  className,
  ...props
}: InputProps) => {
  const [currentPlaceholder, setCurrentPlaceholder] = useState("");
  const activeInputTypes = ["date"];

  return (
    <div className={className}>
      <Container error={!!error} disabled={disabled} tabIndex={-1}>
        <Label
          active={
            !!value || value === 0 || !!currentPlaceholder || (props.type && activeInputTypes.includes(props.type))
          }
          disabled={disabled}
        >
          {label}
        </Label>
        <Input
          {...props}
          value={value}
          placeholder={currentPlaceholder}
          onFocus={() => setCurrentPlaceholder(placeholder || "")}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
            setCurrentPlaceholder("");
          }}
          disabled={disabled}
        />
      </Container>
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};

export default InputField;

import { css } from "styled-components";

import { Alignment } from "../index";

export const Button = css<{ tint: Theme; align: Alignment; disabled?: boolean }>`
  background-color: ${({ disabled }) => !disabled && "transparent"};
  background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  color: ${({ theme }) => theme.palette.white.main};
  color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  box-shadow: inset 0 0 0 2px ${({ tint, disabled }) => !disabled && tint.main};

  &:hover,
  &:active,
  &:focus {
    box-shadow: ${({ disabled }) => !disabled && "none"};
    background-color: ${({ tint, disabled }) => !disabled && tint.tint};
    color: ${({ theme, disabled }) => !disabled && theme.palette.black.main};
  }
`;

export const IconWrapper = css`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;

    path,
    g {
      stroke: currentColor;
    }
  }
`;

import * as React from "react";
import { SVGProps } from "react";

const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(6 4)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M0 6h12v4a6 6 0 0 1-6 6h0a6 6 0 0 1-6-6V6ZM3 3a3 3 0 0 1 3-3h0a3 3 0 0 1 3 3v3H3V3Z" />
      <circle cx={6} cy={10} r={1} />
      <path d="M6 9.5V13" />
    </g>
  </svg>
);

export default SvgLock;

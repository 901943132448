import { css } from "styled-components";

import { Alignment } from "../../index";

export const RadioButtonContainer = css<{ tint: Theme; align: Alignment; disabled?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  border-radius: 28px;
  border: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  padding: 16px;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.white.main};
  color: ${({ disabled, theme }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  &::before {
    content: "";
    box-sizing: border-box;
    background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
    border: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 14px;
  }
  &::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: ${({ theme }) => `${theme.palette.white.main}E8`};
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-right: 14px;
    margin-left: 5px;
    transform: scale(0);
  }

  &:focus-visible {
    content: "";
    outline: 3px solid ${({ theme }) => theme.palette.white.main} !important;
    border-radius: 28px;
    ${({ align }) => align === "left" && `border-top-left-radius: 0; border-bottom-left-radius: 0;`}

    &::before {
      border-color: ${({ disabled, theme }) => !disabled && theme.palette.white.main};
    }
  }

  &:focus {
    outline: none;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Input = css<{ tint?: Theme; disabled?: boolean }>`
  display: none;

  &:checked {
    pointer-events: none;
  }
`;

import * as React from "react";
import { SVGProps } from "react";

const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(2 4)" fill="none" fillRule="evenodd">
      <path
        stroke="#E82F42"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 16H0L10 0zM10 10V6"
      />
      <circle fill="#E82F42" fillRule="nonzero" cx={10} cy={13} r={1} />
    </g>
  </svg>
);

export default SvgError;

import * as React from "react";
import { SVGProps } from "react";

const SvgCar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(3 7)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={4} cy={10} r={2} />
      <circle cx={13} cy={10} r={2} />
      <path d="M2 10H0V3l3-3h9l1 3h1a4 4 0 0 1 4 4v3h0-3M6 10h5" />
    </g>
  </svg>
);

export default SvgCar;

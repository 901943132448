import React, { useContext } from 'react'
import { createGlobalStyle, DefaultTheme, ThemeContext, ThemeProvider } from 'styled-components'
import { normalize as _normalize } from 'styled-normalize'

import { CoverTheme, defaultTheme } from './styles'
import { mergeDeep } from './utils/merge'

const GlobalStyle = createGlobalStyle<{ normalize: boolean }>`
:root {
  --onboard-primary-1: #34d080;
  --onboard-primary-100: #E5F9EF;
  --onboard-primary-200: #D0F5E2;
  --onboard-primary-300: #ADF2CE;
  --onboard-primary-400: #93EEBF;
  --onboard-primary-500: #6FE7A9;
  --onboard-primary-600: #3E9367;
  --onboard-primary-700: #256F49;
  --onboard-wallet-columns: 1;
  overflow-y: scroll;
}

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Graphik', sans-serif;
    overscroll-behavior-y: none;
    caret-color: currentColor;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    font-kerning: none;
    font-feature-settings: 'kern' off;
  }

  ${({ normalize }) => normalize && _normalize}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border:none !important;
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:not(input:-webkit-autofill)::-webkit-contacts-auto-fill-button {
    background-color: white;
  }
  input:not(input:-webkit-autofill)::-webkit-contacts-auto-fill-button:hover {
    background-color: white;
  }
`

type ProviderType = {
  children: JSX.Element | JSX.Element[]
  theme?: DefaultTheme
  normalize?: boolean
}

export const useTheme = (): CoverTheme => useContext(ThemeContext)

export const Provider: React.FC<ProviderType> = ({ children, theme, normalize = false }: ProviderType) => {
  return (
    <ThemeProvider theme={mergeDeep(defaultTheme, theme)}>
      {children}
      <GlobalStyle normalize={normalize} />
    </ThemeProvider>
  )
}

import * as React from "react";
import { SVGProps } from "react";

const SvgPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <defs>
      <path
        d="M3 15.749h5.25V21H3v-5.251Zm11.25 0V21h-4.5v-5.251h4.5Zm6.75 0V21h-5.25v-5.251H21Zm0-1.5h-5.25v-4.5H21v4.5Zm-18-4.5h5.25v4.5H3v-4.5Zm11.25 0v4.5h-4.5v-4.5h4.5Zm6.75-1.5h-5.25V3H21v5.249ZM8.25 3v5.249H3V3h5.25Zm6 0v5.249h-4.5V3h4.5Z"
        id="Placeholder_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="Placeholder_svg__b" fill="#fff">
        <use xlinkHref="#Placeholder_svg__a" />
      </mask>
      <g
        mask="url(#Placeholder_svg__b)"
        stroke="#0F274F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <rect width={16} height={16} rx={4} transform="translate(4 4)" />
      </g>
    </g>
  </svg>
);

export default SvgPlaceholder;

import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useConnectWallet } from '@web3-onboard/react'

import Step, { _Step, StepProps } from './components/Step'

type StepperProps = {
  steps: StepProps[]
  expanded: number
  setExpanded: (expanded: number) => void
}

const styles = css`
  ${_Step}:last-child {
  }
`

const _Stepper = styled.div`
  ${styles}
`

const Stepper: React.FC<StepperProps> = ({ steps, expanded, setExpanded }) => {
  const [walletState] = useConnectWallet()

  useEffect(() => {
    if (!walletState.wallet) {
      setExpanded(0)
    }
  }, [expanded, walletState.wallet])

  return (
    <_Stepper>
      {steps.map((step, index) => {
        return <Step key={index} {...step} expanded={expanded === index} completed={expanded > index} />
      })}
    </_Stepper>
  )
}

export default Stepper

import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import dynamic from "next/dynamic";
import React, { useContext, useState } from "react";

import { ModalProps } from "@/components/Modal/Modal";

// eslint-disable-next-line @typescript-eslint/ban-types
const Modal = dynamic<ModalProps>(() => import("../components/Modal").then((mod) => mod.Modal));

export type ModalValue = {
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
};

export const ModalContext = React.createContext<ModalValue>({} as ModalValue);

export const useModal = () => useContext(ModalContext);

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }: ModalProviderProps) => {
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const openModal = (props: ModalProps) => {
    setModalProps(props);
    setOpen(true);
    disableBodyScroll(document.body);
  };

  const closeModal = () => {
    setOpen(false);
    setModalProps(null);
    clearAllBodyScrollLocks();
  };

  return (
    <>
      <ModalContext.Provider value={{ openModal, closeModal }}>{children}</ModalContext.Provider>
      {open && modalProps && (
        <Modal
          {...modalProps}
          onClose={() => {
            modalProps.onClose?.();
            closeModal();
          }}
        />
      )}
    </>
  );
};

import { css } from "styled-components";

export const Link = css<{ tint: Theme; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: bold;
  border: none;
  background: none;
  position: relative;
  padding: 16px;
  border-radius: 100px;
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
  outline: none;
  color: ${({ theme }) => theme.palette.white.main};
  color: ${({ disabled, theme }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  overflow: hidden;
  -webkit-mask-image: radial-gradient(white, black);

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 32px);
    height: 2px;
    bottom: 8px;
    background-color: ${({ theme }) => theme.palette.white.main};
    background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  }

  &:hover,
  &:active,
  &:focus {
    &:before {
      height: ${({ disabled }) => !disabled && "2px"};
      bottom: ${({ disabled }) => !disabled && "7.5px"};
      background-color: ${({ tint, disabled }) => !disabled && tint.main};
    }
  }
`;

export const IconWrapper = css`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;

    path,
    g {
      stroke: currentColor;
    }
  }
`;

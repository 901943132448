import * as React from "react";
import { SVGProps } from "react";

const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(2 4)" fill="none" fillRule="evenodd">
      <path
        d="M20 5v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5L8.406.89A2 2 0 0 1 10.07 0h3.86a2 2 0 0 1 1.664.89L17 3h1a2 2 0 0 1 2 2Z"
        stroke="#0F274F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="#0F274F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 2v1h2V2zM16 9a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      />
      <rect fill="#0F274F" fillRule="nonzero" x={2} y={5} width={4} height={2} rx={1} />
    </g>
  </svg>
);

export default SvgCamera;

import * as React from "react";
import { SVGProps } from "react";

const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(6 3)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={5} cy={5} r={5} />
      <path d="M0 10h6a6 6 0 0 1 6 6v2h0H0v-8Z" />
    </g>
  </svg>
);

export default SvgProfile;

import styled from "styled-components";

export const Wrapper = styled.div`
  progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;

    height: 8px;
    width: 100%;
    background: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`};
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    cursor: pointer;

    /** chrome, safari, opera, edge chromium */
    ::-webkit-progress-bar {
      border-radius: 0.5rem;
      height: 8px;
      background: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.none}`};
    }

    ::-webkit-progress-value {
      border-radius: 0.5rem;
      height: 8px;
      background: ${({ theme }) => theme.palette.mint.main};
    }

    ::-moz-progress-bar {
      border-radius: 0.5rem;
      height: 8px;
      background: ${({ theme }) => theme.palette.mint.main};
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 8px 0;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.palette.white.main};
`;

export const Percentage = styled.span`
  color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
`;

import axios from "axios";
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

type SecuritizeProps = {
  investorId: string;
  setInvestorId: Dispatch<SetStateAction<string>>;
  kycStatus: string;
  setKycStatus: Dispatch<SetStateAction<string>>;
  loadingKycStatus: boolean;
  setLoadingKycStatus: Dispatch<SetStateAction<boolean>>;
  loadKycStatus: () => void;
};

export const SecuritizeContext = createContext<SecuritizeProps>({} as SecuritizeProps);
export const useSecuritize = () => useContext(SecuritizeContext);

export const SecuritizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sessionInvestorId, setSessionInvestorId] = useSessionStorage("investorId", "");
  const [sessionKycStatus, setSessionKycStatus] = useSessionStorage("kycStatus", "");
  const [investorId, setInvestorId] = useState(sessionInvestorId);
  const [kycStatus, setKycStatus] = useState(sessionKycStatus);
  const [loadingKycStatus, setLoadingKycStatus] = useState(false);

  useEffect(() => {
    setSessionKycStatus(kycStatus);
    setSessionInvestorId(investorId);
  }, [investorId, kycStatus]);

  const loadKycStatus = () => {
    if (investorId && kycStatus !== "verified") {
      setLoadingKycStatus(true);

      axios
        .get("/api/kyc-status?investorId=" + investorId)
        .then(({ data }) => {
          setKycStatus(data?.kycStatus);
        })
        .catch(console.log)
        .finally(() => setLoadingKycStatus(false));
    }
  };

  return (
    <SecuritizeContext.Provider
      value={{
        investorId,
        setInvestorId,
        kycStatus,
        setKycStatus,
        loadKycStatus,
        loadingKycStatus,
        setLoadingKycStatus,
      }}
    >
      {children}
    </SecuritizeContext.Provider>
  );
};

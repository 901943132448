import React from 'react'
import styled from 'styled-components'

import { Alignment } from '../../index'
import { convertTint } from '../../utils/theme'
import { Checkmark as CheckmarkSvg } from '../Icons'
import { CheckboxContainer as _CheckboxContainer, Input as _Input, StyledCheckbox as _StyledCheckbox } from './styles'

const Input = styled.input`
  ${_Input}
`

const StyledCheckbox = styled.div`
  ${_StyledCheckbox}
`

const Checkmark = styled(CheckmarkSvg)`
  transform: scale(0.75);

  margin-top: -1px;
  margin-left: -1px;

  visibility: hidden;
`

const Wrapper = styled.label<{ tint: Theme; disabled?: boolean }>`
  ${_CheckboxContainer}

  ${Input}:checked + ${StyledCheckbox} {
    background-color: ${({ disabled, tint }) => !disabled && tint.main};
    border-color: ${({ disabled, tint }) => !disabled && tint.main};

    ${Checkmark} {
      visibility: visible;

      path {
        stroke: ${({ theme, disabled }) => (disabled ? theme.palette.white.main : theme.palette.black.main)};
      }
    }
  }

  ${Input}:checked:hover + ${StyledCheckbox} {
    background-color: ${({ disabled, tint }) => !disabled && tint.tint};
    border-color: ${({ disabled, tint }) => !disabled && tint.tint};
  }

  &:hover {
    ${StyledCheckbox} {
      transition: linear 0.1s border-color;
      border-color: ${({ disabled, theme }) => !disabled && theme.palette.white.main};
    }
  }
`

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'>, SC {
  tint?: Tint
  align?: Alignment
}

const Checkbox: React.FC<CheckboxProps> = ({
  tint = 'mint',
  align = 'center',
  disabled,
  children,
  value,
  className,
  ...props
}: CheckboxProps) => {
  const color = convertTint(tint)

  return (
    <Wrapper tint={color} disabled={disabled} className={className}>
      <Input {...props} value={value} type="checkbox" disabled={disabled} />
      <StyledCheckbox align={align} disabled={disabled} tabIndex={0}>
        <Checkmark />
      </StyledCheckbox>
      {children}
    </Wrapper>
  )
}

export default Checkbox

import { css } from "styled-components";

const Caption = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.white.main};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default Caption;

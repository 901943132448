import * as React from 'react'
import { SVGProps } from 'react'
import styled from 'styled-components'

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  transform-origin: center;
  animation: spin 2s linear infinite;
  margin-right: 12px;

  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`

const SvgButtonSpinner = (props: SVGProps<SVGSVGElement>) => (
  <Spinner>
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
      <g opacity={0.5} stroke="#F4F4F4" strokeWidth={1.5} strokeLinecap="round">
        <path d="M12 9V3" />
        <path opacity={0.6} d="M12 21v-6" />
        <path opacity={0.9} d="M9.879 9.879 5.636 5.636" />
        <path opacity={0.5} d="m18.364 18.364-4.243-4.243" />
        <path opacity={0.8} d="M9 12H3" />
        <path opacity={0.4} d="M21 12h-6" />
        <path opacity={0.7} d="m9.879 14.121-4.243 4.243" />
        <path opacity={0.3} d="m18.364 5.636-4.243 4.243" />
      </g>
    </svg>
  </Spinner>
)

export default SvgButtonSpinner

import * as React from "react";
import { SVGProps } from "react";

const SvgMoreVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM13.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM13.5 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgMoreVertical;

import * as React from "react";
import { SVGProps } from "react";

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(4 4)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <circle cx={6} cy={6} r={6} />
      <path d="m16 16-5.5-5.5" />
    </g>
  </svg>
);

export default SvgSearch;

import { css } from 'styled-components'

import { Alignment } from '../index'

export const Button = css<{ tint: Theme; align: Alignment; disabled?: boolean }>`
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ tint, disabled }) => !disabled && tint.main};
  background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  padding: 0 24px;
  border: none;
  ${({ theme, disabled }) =>
    disabled && `box-shadow: 0 0 0 2px ${theme.palette.white.main}${theme.opacity.half} inset`};
  border-radius: 100px;
  ${({ align }) => align === 'left' && `border-top-left-radius: 0; border-bottom-left-radius: 0;`}
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.black.main};
  color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  max-height: 56px;
  height: 56px;
  outline: none;
  position: relative;

  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  &:hover {
    background-color: ${({ tint, disabled }) => !disabled && tint.tint};
  }

  &:focus {
    border: 3px solid ${({ theme }) => theme.palette.white.main};
    background-color: ${({ tint }) => tint.tint};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-height: 48px;
    height: 48px;
    font-size: 16px;
  }
`

export const IconWrapper = css`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;

    path,
    g {
      stroke: currentColor;
    }
  }
`

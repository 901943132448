import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import dynamic from "next/dynamic";
import React, { ReactNode, useContext, useState } from "react";

const Modal = dynamic<{ body?: ReactNode }>(() => import("../components/Modal").then((mod) => mod.LoadingModal));

export type LoadingModalValue = {
  openModal: (props: { body?: ReactNode }) => void;
  closeModal: () => void;
};

export const LoadingModalContext = React.createContext<LoadingModalValue>({} as LoadingModalValue);

export const useLoadingModal = () => useContext(LoadingModalContext);

type LoadingModalProviderProps = {
  children: React.ReactNode;
};

export const LoadingModalProvider: React.FC<LoadingModalProviderProps> = ({ children }: LoadingModalProviderProps) => {
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState<ReactNode>(null);

  const openModal = ({ body }: { body?: ReactNode }) => {
    setOpen(true);
    setBody(body);
    disableBodyScroll(document.body);
  };

  const closeModal = () => {
    setOpen(false);
    clearAllBodyScrollLocks();
  };

  return (
    <>
      <LoadingModalContext.Provider value={{ openModal, closeModal }}>{children}</LoadingModalContext.Provider>
      {open && <Modal body={body} />}
    </>
  );
};

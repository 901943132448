import * as React from "react";
import { SVGProps } from "react";

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M4 20h16" />
      <path d="M7 17h4l7.586-7.586a2 2 0 0 0 0-2.828l-1.172-1.172a2 2 0 0 0-2.828 0L7 13h0v4Z" strokeLinejoin="round" />
      <path d="m17 11-4-4" />
    </g>
  </svg>
);

export default SvgEdit;

import { css } from "styled-components";

const Large = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: initial;
  color: ${({ theme }) => theme.palette.white.main};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
    line-height: 20px;
    text-transform: initial;
  }
`;

export default Large;

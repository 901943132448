import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

import { convertTint } from '../../../utils/theme'
import { ButtonProps } from '../index'
import { Button as _Button, IconWrapper as _IconWrapper } from './styles'
import { ButtonSpinner } from '../../Icons'

const Button = styled.button`
  ${_Button}
`

const IconWrapper = styled.div`
  ${_IconWrapper}

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
`

const PrimaryButton: React.FC<ButtonProps> = ({
  tint = 'mint',
  align = 'center',
  icon,
  iconPosition = 'right',
  children,
  loading,
  ...props
}) => {
  const color = convertTint(tint)
  return (
    <Button tint={color} align={align} {...props} disabled={props.disabled || loading}>
      {loading && <ButtonSpinner />}
      {!loading && icon && iconPosition === 'left' && <IconWrapper>{icon}</IconWrapper>}
      <span>{children}</span>
      {icon && iconPosition === 'right' && <IconWrapper>{icon}</IconWrapper>}
    </Button>
  )
}

export default PrimaryButton

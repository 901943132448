import { css } from "styled-components";

import { Container as _Container, Input as _Input, Label as _Label } from "../Input/InputField/styles";

export const Container = css`
  ${_Container}
  max-height: none;
`;

export const Input = css`
  ${_Input}
  height: 100%;
`;

export const Label = css<{ active?: boolean; disabled?: boolean }>`
  ${_Label}
  margin: 0;
  padding-top: 8px;
  margin-top: -8px;
  width: calc(100% - 48px);
  ${({ active }) => active && `width: 120%`}
`;

import * as React from "react";
import { SVGProps } from "react";

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(4 4)" fill="none" fillRule="evenodd">
      <path stroke="#0F274F" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M0 2h16v14H0z" />
      <path stroke="#0F274F" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M0 2h16v4H0z" />
      <rect fill="#0F274F" fillRule="nonzero" x={11} y={11} width={3} height={3} rx={1} />
      <path stroke="#0F274F" strokeWidth={1.5} strokeLinecap="round" d="M3 4V0M13 4V0" />
    </g>
  </svg>
);

export default SvgCalendar;

import React, { useState } from "react";
import styled from "styled-components";

import { ErrorMessage as _ErrorMessage } from "../../Typography";
import { Container as _Container, Input as _Input, Label as _Label } from "./styles";

const Label = styled.label`
  ${_Label}
`;

const Container = styled.div`
  ${_Container}

  &:focus-within ${Label} {
    transform: translate(8px, 8px) scale(0.77);
  }
`;

const Input = styled.input`
  ${_Input}
`;

const ErrorMessage = styled(_ErrorMessage)`
  margin-left: 16px;
`;

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, SC {
  label: string;
  valid?: boolean;
  error?: string;
}

const RoundedInput: React.FC<InputProps> = ({
  label,
  value,
  error,
  disabled,
  placeholder,
  className,
  ...props
}: InputProps) => {
  const [currentPlaceholder, setCurrentPlaceholder] = useState("");
  const activeInputTypes = ["date"];

  return (
    <div className={className}>
      <Container error={!!error} disabled={disabled}>
        <Label
          active={!!value || !!currentPlaceholder || (props.type && activeInputTypes.includes(props.type))}
          disabled={disabled}
        >
          {label}
        </Label>
        <Input
          {...props}
          value={value}
          placeholder={currentPlaceholder}
          onFocus={() => setCurrentPlaceholder(placeholder || "")}
          onBlur={() => setCurrentPlaceholder("")}
          disabled={disabled}
        />
      </Container>
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};

export default RoundedInput;

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Checkmark, Close, Warning } from '../../Icons'
import { Heading, Icon, StepContent, StepDetail, StepDivider, StepHeader } from '.'

export type StepProps = {
  title: string
  body?: React.ReactNode
  expanded?: boolean
  completed?: boolean
  onComplete?: () => void
  hasWarning?: boolean
  hasError?: boolean
}

export const _Step = styled.div<{ expanded?: boolean; completed?: boolean; hasWarning?: boolean; hasError?: boolean }>`
  display: flex;
  grid-gap: 1rem;

  &:last-child ${StepDivider}, &:last-child ${StepContent} {
    ${({ expanded }) => !expanded && `display: none;`}
  }

  p {
    line-height: 1.5;
  }
`

const Step: React.FC<StepProps> = ({ title, expanded, completed, body, onComplete, hasWarning, hasError }) => {
  useEffect(() => {
    if (expanded && !hasWarning && !hasError && !completed && onComplete) {
      onComplete()
    }
  }, [expanded, completed, hasWarning, hasError, onComplete])

  return (
    <_Step expanded={expanded} completed={completed} hasWarning={hasWarning} hasError={hasError}>
      <StepHeader>
        <Icon completed={completed} hasWarning={hasWarning} hasError={hasError}>
          {hasWarning ? <Warning /> : hasError ? <Close /> : completed ? <Checkmark /> : null}
        </Icon>
        <StepDivider hasWarning={hasWarning} hasError={hasError} />
      </StepHeader>

      <StepDetail expanded={expanded}>
        <Heading disabled={!expanded && !completed}>{title}</Heading>
        {expanded && <StepContent>{body}</StepContent>}
      </StepDetail>
    </_Step>
  )
}

export default Step

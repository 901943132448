import React from "react";
import styled from "styled-components";

import { ErrorMessage as _ErrorMessage } from "../Typography";
import { Container as _Container, Input as _Input, Label as _Label } from "./styles";

const Label = styled.label<{ active?: boolean; disabled?: boolean }>`
  ${_Label}
`;

const Container = styled.div<{ error?: boolean; disabled?: boolean }>`
  ${_Container}

  &:focus-within ${Label} {
    transform: translateY(8px) scale(0.77);
  }
`;

const Input = styled.textarea`
  ${_Input}
  resize: none;
`;

const ErrorMessage = styled(_ErrorMessage)``;

export interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement>, SC {
  label: string;
  valid?: boolean;
  error?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, error, placeholder, disabled, value, className, ...props }) => {
  return (
    <>
      <Container error={!!error} disabled={disabled} className={className}>
        <Label active={!!value} disabled={disabled}>
          {label}
        </Label>
        <Input
          {...props}
          value={value}
          onFocus={(e) => (e.target.placeholder = placeholder || "")}
          onBlur={(e) => (e.target.placeholder = "")}
          disabled={disabled}
        />
      </Container>
      <ErrorMessage>{error}</ErrorMessage>
    </>
  );
};

export default TextArea;

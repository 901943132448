import React, { useState } from "react";
import styled from "styled-components";

import useOnClickOutside from "../../hooks/useOutsideClick";
import { ChevronDown } from "../Icons";
import { Container as _Container, Input as _Input, Label as _Label } from "../Input/InputField/styles";
import { ErrorMessage } from "../Typography";
import { Dropdown as _Dropdown, DropdownItem as _DropdownItem, SelectContainer } from "./styles";

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ active?: boolean; error?: boolean; disabled?: boolean }>`
  ${_Container}
  ${SelectContainer}
  position: relative;

  ${({ active }) => active && `border: none;`}
`;

const Label = styled.p<{ active?: boolean; disabled?: boolean }>`
  ${_Label}
  margin: 0;
`;

const Input = styled.input<{ autocomplete?: boolean; disabled?: boolean }>`
  ${_Input}
  max-width: 85%;

  ${({ autocomplete }) => !autocomplete && `cursor: pointer; caret-color: transparent;`}
  ${({ disabled }) => disabled && `opacity: 0;`}
`;

const Dropdown = styled.ul`
  ${_Dropdown}
`;

const DropdownItem = styled.button`
  ${_DropdownItem}
`;

const Icon = styled.div<{ $expanded: boolean; autocomplete?: boolean; disabled?: boolean }>`
  position: absolute;
  top: 16px;
  right: 24px;
  transform: rotate(${({ $expanded, autocomplete }) => ($expanded && !autocomplete ? "180deg" : "0deg")});
  transition: all 0.2s ease-in-out;
  ${({ disabled, theme }) => `color: ${theme.palette.white.main}${disabled ? theme.opacity.half : ""}`};

  svg {
    width: 100%;
    height: 100%;

    path,
    g {
      stroke: currentColor;
    }
  }
`;

type OptionType = {
  label: string;
  value?: any;
};

export interface SelectProps extends React.ComponentPropsWithoutRef<"input">, SC {
  label: string;
  options: OptionType[];
  autocomplete?: boolean;
  error?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const Select: React.FC<SelectProps> = ({
  label,
  value = "",
  options,
  onChange,
  onClick,
  autocomplete,
  disabled,
  error,
  icon = <ChevronDown />,
  className,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useOnClickOutside(wrapperRef, () => setActive(false));

  return (
    <Wrapper className={className} ref={wrapperRef}>
      <Container
        active={active}
        error={!!error}
        disabled={disabled}
        onClick={() => {
          setActive(!active && !disabled);
        }}
      >
        <Label active={active || !!value || !!inputRef.current?.value} disabled={disabled}>
          {label}
        </Label>
        <Input
          {...props}
          ref={inputRef}
          value={autocomplete ? value : options.find((option) => option.value === value)?.label}
          autocomplete={autocomplete}
          disabled={disabled}
          readOnly={!autocomplete}
          onChange={(e) => {
            if (!autocomplete) {
              e.target.value = options.find((option) => option.label === e.target.value)?.value || "";
            }
            onChange && onChange(e);
          }}
        />

        <Icon $expanded={active} autocomplete={autocomplete} disabled={disabled}>
          {icon}
        </Icon>
      </Container>
      <Dropdown active={active}>
        {options.map((option) => (
          <li key={option.value}>
            <DropdownItem
              value={option.value}
              type="button"
              onClick={(e) => {
                const { value } = (e.target as HTMLButtonElement) || "";
                const setter = Object.getOwnPropertyDescriptor(window?.HTMLInputElement.prototype, "value")?.set;

                setter?.call(inputRef.current, options.find((option) => option.value === value)?.label);
                inputRef.current?.dispatchEvent(new Event("input", { bubbles: true }));

                setActive(false);

                onClick && onClick(e as React.MouseEvent<HTMLInputElement>);
              }}
            >
              {option.label}
            </DropdownItem>
          </li>
        ))}
      </Dropdown>
      <ErrorMessage>{error}</ErrorMessage>
    </Wrapper>
  );
};

export default Select;

import * as React from 'react'
import { SVGProps } from 'react'

const SvgLogo: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.359 4.406c-.538-.074-1.746-.173-1.746 3.124L5.565 16H0V.312L5.108.294v1.99C6.324-.08 9.918-.467 12 .475v6.683c-.226.052-.639.129-.847.158-2.75.387-3.831-1.23-3.794-2.91Zm14.877-.53c-1.619 0-2.719.898-3.033 2.391h6.066c-.262-1.56-1.373-2.392-3.033-2.392ZM22.126 0C26.943 0 30 3.464 30 7.787V9.4l-10.798-.014c.098 1.221 1.471 2.438 3.908 2.438 1.893 0 3.277-.52 3.944-1.048l1.887 3.672c-1.118.664-3.49 1.553-6.275 1.553C16.932 16 14 12.332 14 7.787 14 3.488 17.185 0 22.125 0Z"
      fill="#fff"
    />
  </svg>
)

export default SvgLogo

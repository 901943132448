import * as React from "react";
import { SVGProps } from "react";

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(2 5)" stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd">
      <rect x={0.75} y={0.75} width={18.5} height={12.5} rx={2} />
      <path d="M9.94 9.275c.31.015.626-.085.877-.303l7.107-7.804L2.261.75l6.814 8.118c.232.255.546.392.866.407Z" />
      <path strokeLinecap="square" d="m12.5 7.5 5 5M2.5 12.5l5-5" />
    </g>
  </svg>
);

export default SvgEmail;

import { ReactNode } from 'react'

export type Alignment = 'left' | 'center'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'>, SC {
  tint?: Tint
  align?: Alignment
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  loading?: boolean
}

export { default as ActionButton } from './Action'
export { default as LinkButton } from './Link'
export { default as PrimaryButton } from './Primary'
export { default as SecondaryButton } from './Secondary'

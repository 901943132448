import { DefaultTheme } from 'styled-components'

export interface CoverTheme extends DefaultTheme {
  palette: {
    [x: string]: Theme
  }
  breakpoints: {
    mobile: string
    tablet: string
    desktop: string
  }
  fonts: {
    primary: string
    secondary: string
  }
  opacity: {
    none: string
    dark: string
    half: string
    light: string
    full: string
  }
  fontWeight: {
    regular: number
    medium: number
  }
}

export const defaultTheme: CoverTheme = {
  palette: {
    black: {
      main: '#000000',
      contrastText: '#ffffff',
      tint: '#191919',
    },
    red: {
      main: '#FA4D56',
      contrastText: '#000000',
      tint: '#BA1B23',
      highlight: '#FA4D56',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    coral: {
      main: '#f78061',
      contrastText: '#000000',
      tint: '#FF9F9F',
      highlight: '#F78061',
    },
    mint: {
      main: '#34d080',
      contrastText: '#000000',
      tint: '#2BB66E',
      highlight: '#34D080',
    },
    yellow: {
      main: '#f8d030',
      contrastText: '#000000',
      tint: '#F6C913',
      highlight: '#F8D030',
    },
    blue: {
      main: '#4fa1f6',
      contrastText: '#ffffff',
      tint: '#2A8BF4',
      highlight: '#4FA1F6',
    },
    grey: {
      main: '#A7AFB9',
      contrastText: '#ffffff',
    },
    lightGrey: {
      main: '#E7EAEE',
      contrastText: '#000000',
    },
    lightBgGrey: {
      main: '#F7F8FA',
      contrastText: '#000000',
    },
    mediumGrey: {
      main: '#6A6F76',
      contrastText: '#000000',
    },
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
    desktop: '1440px',
  },
  fonts: {
    primary: 'Graphik, sans-serif',
    secondary: 'Capian, sans-serif',
  },
  opacity: {
    none: '00',
    dark: '59',
    half: '7F',
    light: 'A6',
    full: 'FF',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
  },
}

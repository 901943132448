import styled from 'styled-components'

import { H3 } from '../../Typography/Heading'

export const Icon = styled.div<{ completed?: boolean; hasWarning?: boolean; hasError?: boolean }>`
  color: ${({ hasWarning, hasError, completed, theme }) =>
    hasWarning
      ? theme.palette.yellow.main
      : hasError
      ? theme.palette.red.main
      : completed
      ? theme.palette.mint.main
      : theme.palette.white.main + theme.opacity.light};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid currentColor;
  display: grid;
  place-items: center;
  /* padding: 4px; */

  svg {
    & > g {
      stroke: currentColor;
    }
    & > path {
      stroke: currentColor;
    }
  }
`

export const StepHeader = styled.header`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
`

export const Heading = styled(H3)<{ disabled?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  ${({ theme, disabled }) => disabled && `color: ${theme.palette.white.main + theme.opacity.dark};`};
`

export const StepDetail = styled.div<{ expanded?: boolean }>`
  display: grid;
  grid-template-rows: min-content 1fr;
`

export const StepContent = styled.div`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.palette.white.main};
`

export const StepDivider = styled.div<{ hasWarning?: boolean; hasError?: boolean }>`
  color: ${({ hasWarning, hasError, theme }) =>
    hasWarning
      ? theme.palette.yellow.main
      : hasError
      ? theme.palette.red.main
      : theme.palette.white.main + theme.opacity.dark};
  border-left: 1px solid currentColor;
  min-height: 24px;
  align-self: stretch;
  margin: 0 16px 20px;
`

import * as React from "react";
import { SVGProps } from "react";

const SvgMilitary = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="m12 19-3.527 1.854.674-3.927-2.853-2.781 3.943-.573L12 10l1.763 3.573 3.943.573-2.853 2.781.674 3.927zM4 7V3h16v4l-8 3z" />
    </g>
  </svg>
);

export default SvgMilitary;

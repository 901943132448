import "ui/assets/fonts/fonts.css";

import { useAccountCenter, useConnectWallet, useWallets } from "@web3-onboard/react";
import type { AppProps } from "next/app";
import { useEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import { Theme } from "ui";
import { useLocalStorage } from "usehooks-ts";

import { SecuritizeProvider } from "@/hooks/securitize";
import { SocketProvider } from "@/hooks/socket";
import { LoadingModalProvider } from "@/hooks/useLoadingModal";
import { ModalProvider } from "@/hooks/useModal";
import queryClient from "@/lib/query-client";
import { initWeb3Onboard } from "@/utils/blocknative";

function MyApp({ Component, pageProps }: AppProps) {
  const updateAccountCenter = useAccountCenter();
  const [web3Onboard, setWeb3Onboard] = useState<typeof initWeb3Onboard>(initWeb3Onboard);
  const connectedWallets = useWallets();
  const [, connect] = useConnectWallet();

  const [localConnectedWallets, setLocalConnectedWallets] = useLocalStorage<string[]>("connectedWallets", []);

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
    updateAccountCenter({ enabled: false });
  }, []);

  useEffect(() => {
    const connectedWalletsLabelArray = (connectedWallets.length && connectedWallets.map(({ label }) => label)) || [];
    setLocalConnectedWallets(connectedWalletsLabelArray);
  }, [connectedWallets]);

  useEffect(() => {
    async function setWalletFromLocalStorage() {
      await connect({ autoSelect: { label: localConnectedWallets[0], disableModals: true } });
    }

    if (localConnectedWallets?.length) {
      setWalletFromLocalStorage();
    }
  }, [web3Onboard, connect]);

  return (
    <Theme.Provider>
      <ModalProvider>
        <LoadingModalProvider>
          <QueryClientProvider client={queryClient}>
            <SecuritizeProvider>
              <SocketProvider>
                <Component {...pageProps} />
              </SocketProvider>
            </SecuritizeProvider>
          </QueryClientProvider>
        </LoadingModalProvider>
      </ModalProvider>
    </Theme.Provider>
  );
}

export default MyApp;

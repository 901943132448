import * as React from "react";
import { SVGProps } from "react";

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0V5Zm-1 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#F1C21B"
    />
  </svg>
);

export default SvgWarning;

import React from "react";
import styled from "styled-components";

import { Wrapper as _Wrapper } from "./styles";

const Wrapper = styled.div`
  ${_Wrapper}
`;

export interface CardProps extends SC {
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ children, ...props }: CardProps) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default Card;

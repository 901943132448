import { css } from "styled-components";

import { Container as _Container, Input as _Input, Label as _Label } from "../InputField/styles";

export const Label = css`
  ${_Label}
`;

export const Input = css<{ disabled?: boolean }>`
  ${_Input}
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-height: 48px;
    font-size: 16px;
  }
`;

export const Container = css<{ tint: Theme; error?: boolean; disabled?: boolean }>`
  ${_Container}
  border: 1px solid ${({ tint, error, disabled }) => !error && !disabled && tint.main};
  ${({ theme, error }) => error && `border: 1px solid ${theme.palette.coral.main}`};
  ${({ theme, disabled }) => disabled && `border: 1px solid ${theme.palette.white.main}${theme.opacity.dark}`};
  border-radius: 0;
  padding-left: 16px;

  &:active,
  &:focus-within {
    border-color: ${({ tint, error, disabled }) => !error && !disabled && tint.main};
  }

  &:hover {
    border-color: ${({ tint, error, disabled }) => !error && !disabled && tint.tint};
  }
`;

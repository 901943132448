import { css } from "styled-components";

export const Label = css<{ active?: boolean; disabled?: boolean }>`
  position: absolute;
  pointer-events: none;
  transform: translate(8px, 18px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => theme.palette.black.main};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 1;
  left: 16px;
  ${({ active }) => active && `transform: translate(8px, 8px) scale(0.77);`}
  ${({ disabled }) => disabled && `opacity: 0.5;`}
`;

export const Input = css<{ disabled?: boolean }>`
  padding: 24px 24px 12px 24px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.black.main};

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.mediumGrey.main};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.mediumGrey.main};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.mediumGrey.main};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.mediumGrey.main};
  }
`;

export const Container = css<{ error?: boolean; disabled?: boolean }>`
  background-color: ${({ theme }) => theme.palette.white.main};
  background-color: ${({ theme, disabled }) => disabled && theme.palette.lightBgGrey.main};
  border: 1px solid ${({ theme }) => theme.palette.grey.main};
  border-color: ${({ theme, error }) => error && theme.palette.red.main};
  border-color: ${({ theme, disabled }) => disabled && theme.palette.lightGrey.main};
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 56px;
  outline: none;
  position: relative;

  &:hover,
  &:active {
    border-color: ${({ theme, error, disabled }) => !error && !disabled && theme.palette.black.main};
  }

  &:focus {
    margin-top: -2px;
    margin-left: -2px;
    border-width: 3px;
    border-color: ${({ theme, error, disabled }) => !error && !disabled && theme.palette.black.main};
  }
`;

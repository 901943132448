import React from "react";
import styled from "styled-components";

import { Alignment } from "../../index";
import { convertTint } from "../../utils/theme";
import { Input as _Input, RadioButtonContainer as _RadioButtonContainer } from "./styles";

const Input = styled.input`
  ${_Input}
`;

const RadioButtonContainer = styled.span`
  ${_RadioButtonContainer}
`;

const Wrapper = styled.label<{ tint: Theme; disabled?: boolean }>`
  ${Input}:checked + ${RadioButtonContainer} {
    transition: linear 0.1s background-color, linear 0.1s border-color;
    background-color: ${({ disabled, tint }) => !disabled && tint.main};
    border-color: ${({ disabled, tint }) => !disabled && tint.main};
    color: ${({ disabled, theme }) => !disabled && theme.palette.black.main};

    &:hover {
      background-color: ${({ disabled, tint }) => !disabled && tint.tint};

      &::before {
        background-color: ${({ disabled, tint }) => !disabled && tint.tint};
      }
    }
  }

  ${Input}:checked + ${RadioButtonContainer}:before {
    transition: ease 0.1s border-width;
    border: 4px solid ${({ disabled, theme }) => !disabled && theme.palette.black.main};
    ${({ disabled }) => disabled && "border-width: 1px;"};
    background-color: ${({ disabled, tint }) => !disabled && tint.main};
  }

  ${Input}:checked:disabled + ${RadioButtonContainer}:after {
    transition: ease 0.1s transform;
    transform: scale(1);
  }

  &:hover {
    ${RadioButtonContainer} {
      transition: linear 0.1s border-color;
      border-color: ${({ disabled, theme }) => !disabled && theme.palette.white.main};
    }

    ${RadioButtonContainer}:before {
      transition: linear 0.1s border-color;
      border-color: ${({ disabled, theme }) => !disabled && theme.palette.white.main};
    }
  }
`;

export interface RadioButtonProps extends React.ComponentPropsWithoutRef<"input">, SC {
  tint?: Tint;
  align?: Alignment;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  tint = "mint",
  align = "center",
  disabled,
  value,
  children,
  name = "default",
  className,
  ...props
}: RadioButtonProps) => {
  const color = convertTint(tint);

  return (
    <Wrapper tint={color} disabled={disabled} className={className}>
      <Input {...props} name={name} value={value} disabled={disabled} type="radio" />
      <RadioButtonContainer tint={color} align={align} disabled={disabled} tabIndex={0}>
        {children}
      </RadioButtonContainer>
    </Wrapper>
  );
};

export default RadioButton;

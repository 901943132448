import React from "react";

import { Search } from "../Icons";
import Select, { SelectProps } from "../Select";

const Autocomplete: React.FC<SelectProps> = (props) => {
  return <Select icon={<Search />} {...props} autocomplete />;
};

export default Autocomplete;

import { css } from 'styled-components'

export const SelectContainer = css<{ active?: boolean }>`
  height: 56px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${({ active, theme }) => active && `border-color: ${theme.palette.black.main};`}
`

export const Dropdown = css<{ active?: boolean }>`
  margin: 0;
  list-style: none;
  padding-left: 0;
  max-height: 0px;
  overflow-y: auto;
  transition: max-height 0.2s ease-in-out;
  border-top: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  ${({ theme, active }) =>
    active &&
    `max-height: 256px; border: 1px solid ${theme.palette.white.main}; background-color: ${theme.palette.black.tint};`}

  /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`

export const DropdownItem = css`
  border: none;
  background-color: transparent;
  width: 100%;
  height: 56px;
  cursor: pointer;
  text-align: left;
  padding-left: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.white.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.mint.tint};
    color: ${({ theme }) => theme.palette.black.main};
  }
`

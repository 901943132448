import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { convertTint } from '../../../utils/theme'
import { ButtonProps } from '../index'
import { Button as _Button } from './styles'

const Button = styled.button`
  ${_Button}

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

const ActionButton: React.FC<ButtonProps> = ({ tint = 'mint', icon, iconPosition = 'left', children, ...props }) => {
  const color = convertTint(tint)
  return (
    <Button tint={color} align="center" {...props}>
      {icon && iconPosition === 'left' && icon}
      <span>{children}</span>
      {icon && iconPosition === 'right' && icon}
    </Button>
  )
}

export default ActionButton

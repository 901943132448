import React from "react";
import styled from "styled-components";

import RadioButton, { RadioButtonProps } from "../index";

const Wrapper = styled.div``;

interface Option {
  label: string;
  value: any;
}

export interface RadioGroupProps extends RadioButtonProps, SC {
  options?: Option[];
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ options = [], name, value, onChange, className, ...props }) => {
  return (
    <Wrapper className={className}>
      {options.map((item) => (
        <RadioButton
          key={item.value}
          children={item.label}
          name={name}
          checked={item.value === value}
          value={item.value}
          onChange={onChange}
          {...props}
        />
      ))}
    </Wrapper>
  );
};

import * as React from "react";
import { SVGProps } from "react";

const SvgPreesent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M19 11v10H5V11h5-7V7h18v4h-7z" stroke="#0F274F" strokeLinejoin="round" />
      <g stroke="#E82F42">
        <path
          d="M10 3a2 2 0 0 1 2 2v2h0-2a2 2 0 1 1 0-4ZM14 3a2 2 0 1 1 0 4h-2 0V5a2 2 0 0 1 2-2Z"
          strokeLinejoin="round"
        />
        <path d="M10 7h4v14h-4z" />
      </g>
    </g>
  </svg>
);

export default SvgPreesent;

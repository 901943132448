import injectedModule from "@web3-onboard/injected-wallets";
import { init } from "@web3-onboard/react";
import Notify from "bnc-notify";

import logo from "../widgets/onboarding/logo";

const injected = injectedModule();

const networkId = 4;
const apiUrl = process.env.REACT_APP_API_URL;
const dappId = "12153f55-f29e-4f11-aa07-90f10da5d778";

const INFURA_ID = "cea9deb6467748b0b81b920b005c10c1";
export const initWeb3Onboard = init({
  wallets: [injected],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum",
      rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    },
    {
      id: "0x3",
      token: "tROP",
      label: "Ropsten",
      rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
    },
    {
      id: "0x4",
      token: "rETH",
      label: "Rinkeby",
      rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    },
    {
      id: "0x38",
      token: "BNB",
      label: "Binance",
      rpcUrl: "https://bsc-dataseed.binance.org/",
    },
    {
      id: "0x89",
      token: "MATIC",
      label: "Polygon",
      rpcUrl: "https://matic-mainnet.chainstacklabs.com",
    },
    {
      id: "0xfa",
      token: "FTM",
      label: "Fantom",
      rpcUrl: "https://rpc.ftm.tools/",
    },
  ],
  appMetadata: {
    name: "Blocknative Web3-Onboard",
    icon: logo,
    logo: logo,
    description: "Demo app for Web3-Onboard",
    recommendedInjectedWallets: [{ name: "MetaMask", url: "https://metamask.io" }],
    agreement: {
      version: "1.0.0",
      termsUrl: "https://www.blocknative.com/terms-conditions",
      privacyUrl: "https://www.blocknative.com/privacy-policy",
    },
    gettingStartedGuide: "https://blocknative.com",
    explore: "https://blocknative.com",
  },
});

export function initNotify() {
  return Notify({
    dappId,
    networkId,
    apiUrl,
    onerror: (error) => console.log(`Notify error: ${error.message}`),
  });
}

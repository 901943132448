import React from 'react'
import styled from 'styled-components'

import { Alignment } from '../../index'
import { convertTint } from '../../utils/theme'
import {
  Input as _Input,
  SegmentContainer as _SegmentContainer,
  SegmentLabel as _SegmentLabel,
  Toggle as _Toggle,
} from './styles'

const SegmentLabel = styled.p`
  ${_SegmentLabel}
`

const Toggle = styled.label`
  ${_Toggle}

  &:first-child ${SegmentLabel} {
    border-radius: 28px 0px 0px 28px;
    border-right: none;
  }

  &:last-child ${SegmentLabel} {
    border-radius: 0px 28px 28px 0px;
    border-left: none;
  }

  &:only-child ${SegmentLabel} {
    border-radius: 28px;
  }
`

const Input = styled.input`
  ${_Input}

  &:checked + ${SegmentLabel} {
    background-color: ${({ tint, disabled }) => !disabled && tint.main};
    border-color: ${({ tint, disabled }) => !disabled && tint.main};
    color: ${({ theme, disabled }) => !disabled && theme.palette.black.main};

    &:hover {
      background-color: ${({ tint, disabled }) => !disabled && tint.tint};
      border-color: ${({ tint, disabled }) => !disabled && tint.tint};
    }
  }
`

export interface SegmentProps extends React.ComponentPropsWithoutRef<'input'>, SC {
  tint?: Tint
  align?: Alignment
  disabled?: boolean
  name?: string
  value: string
}

const Segment: React.FC<SegmentProps> = ({
  tint = 'mint',
  align = 'center',
  name = 'default',
  disabled = false,
  value,
  children,
  className,
  ...props
}: SegmentProps) => {
  const color = convertTint(tint)

  return (
    <Toggle>
      <Input {...props} value={value} name={name} tint={color} disabled={disabled} type="radio" hidden />
      <SegmentLabel tint={color} disabled={disabled} align={align} tabIndex={0}>
        {children}
      </SegmentLabel>
    </Toggle>
  )
}

export default Segment

import styled from "styled-components";

import styles from "./styles";

const Body = styled.p<{ secondary?: boolean }>`
  ${styles}
  font-size: ${({ secondary }) => (secondary ? "16px" : "18px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ secondary }) => (secondary ? "14px" : "16px")};
    line-height: ${({ secondary }) => (secondary ? "16px" : "24px")};
  }
`;

export default Body;

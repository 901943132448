import * as React from "react";
import { SVGProps } from "react";

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5 11h14v10H5z" />
      <path d="M10 15h4v6h-4zM10 5h6c2.761 0 5 2.686 5 6h0H3V5h4" />
      <path d="M7 2h3v3a3 3 0 0 1-3 3h0V2Z" />
    </g>
  </svg>
);

export default SvgHome;

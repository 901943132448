import * as React from "react";
import { SVGProps } from "react";

const SvgMileage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(4 4)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={8} cy={8} r={8} />
      <path d="M1 11h14M8 11 4 7" />
    </g>
  </svg>
);

export default SvgMileage;

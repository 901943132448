import { Label, LabelWrapper, Percentage, Wrapper } from "./components";

export type ProgressProps = {
  value: number;
  max: number;
  label: string;
};

const Progress: React.FC<ProgressProps> = ({ value, max, label }) => {
  return (
    <Wrapper>
      <progress value={value} max={max} />
      <LabelWrapper>
        <Label>{label}</Label>
        <Percentage>{Math.round((value / max) * 100)}% complete</Percentage>
      </LabelWrapper>
    </Wrapper>
  );
};

export default Progress;

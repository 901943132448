import React from "react";
import styled from "styled-components";

import { ChevronDown } from "../../Icons";
import H4 from "../../Typography/Heading/H4/styles";
import Caption from "../../Typography/Text/Caption/styles";
import Card from "../index";
import { CardProps } from "../index";
import {
  CollapsibleContent as _CollapsibleContent,
  Header as _Header,
  Icon as _Icon,
  IconSection as _IconSection,
  InnerContent as _InnerContent,
  Input as _Input,
  Title as _Title,
} from "./styles";

const Wrapper = styled(Card)`
  padding: 0px;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CollapsibleContent = styled.div<{ expanded: boolean }>`
  ${_CollapsibleContent}

  ${({ expanded }) => expanded && `max-height: 10000px; visibility: visible;`};
`;

const InnerContent = styled.div`
  ${_InnerContent}
`;

const Header = styled.div`
  ${_Header}
`;

const Title = styled.h4`
  ${H4}
`;

const Subtitle = styled.p`
  ${Caption}
  padding-bottom: 8px;
`;

const IconSection = styled.div`
  ${_IconSection}
`;

const Icon = styled(ChevronDown)`
  ${_Icon}
`;

export interface CollapsibleCardProps extends CardProps {
  title: string;
  subtitle: string;
  action?: React.ReactNode;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({ title, subtitle, action, children, ...props }) => {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Wrapper {...props}>
      <Header
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
        </div>
        <IconSection>
          {action && <div>{action}</div>}
          <Icon expanded={expanded} />
        </IconSection>
      </Header>
      <CollapsibleContent expanded={expanded}>
        <InnerContent>{children}</InnerContent>
      </CollapsibleContent>
    </Wrapper>
  );
};

export default CollapsibleCard;

import { defaultTheme } from '../styles'

export const convertTint = (tint: Tint): Theme => {
  switch (tint) {
    case 'mint':
      return defaultTheme.palette.mint
    case 'coral':
      return defaultTheme.palette.coral
    case 'yellow':
      return defaultTheme.palette.yellow
    case 'blue':
      return defaultTheme.palette.blue
    case 'red':
      return defaultTheme.palette.red
    default:
      return defaultTheme.palette.mint
  }
}

import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import io, { Socket } from "socket.io-client";

import { useSecuritize } from "./securitize";

const ws = process.env.NEXT_PUBLIC_APP_SERVER_URL || "";

enum SocketEvents {
  securitizeKycUpdate = "securitize-kyc-update",
}

type SocketContextValue = {
  socket: Socket | null;
};
const SocketContext = createContext({} as SocketContextValue);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { investorId, loadKycStatus } = useSecuritize();
  let socket: Socket | null = null;

  useEffect(() => {
    if (socket?.connected) return;
    socket = io(ws, {
      withCredentials: true,
    });

    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    socket?.on(SocketEvents.securitizeKycUpdate, loadKycStatus);
    return () => {
      socket?.off(SocketEvents.securitizeKycUpdate, loadKycStatus);
    };
  }, [socket, investorId]);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

import { css } from "styled-components";

const H1 = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.palette.white.main};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 30px;
    line-height: 34px;
  }
`;

export default H1;

import * as React from "react";
import { SVGProps } from "react";

const SvgEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 8A2.75 2.75 0 0 1 5 5.25h14A2.75 2.75 0 0 1 21.75 8v8A2.75 2.75 0 0 1 19 18.75H5A2.75 2.75 0 0 1 2.25 16V8Zm1.517-.207A1.258 1.258 0 0 0 3.75 8v8c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V8c0-.07-.006-.14-.017-.207l-6.422 5.619a2.75 2.75 0 0 1-3.622 0l-6.422-5.62Zm1.095-1.035 6.315 5.525a1.25 1.25 0 0 0 1.646 0l6.315-5.525A1.261 1.261 0 0 0 19 6.75H5c-.047 0-.093.003-.138.008Z"
      fill="#000"
    />
  </svg>
);

export default SvgEnvelope;

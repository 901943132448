import * as React from "react";
import { SVGProps } from "react";

const SvgMoreHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#0F274F" fillRule="nonzero">
      <path d="M12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM17 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM7 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" />
    </g>
  </svg>
);

export default SvgMoreHorizontal;

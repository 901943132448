import { css } from "styled-components";

const H2 = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.266667px;
  color: ${({ theme }) => theme.palette.white.main};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export default H2;

import * as React from "react";
import { SVGProps } from "react";

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#0F274F" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M12 20V4" />
      <path strokeLinejoin="round" d="m6 14 6 6 6-6" />
    </g>
  </svg>
);

export default SvgArrowDown;

import * as React from "react";
import { SVGProps } from "react";

const SvgClear = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(4 4)"
      stroke="#0F274F"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <circle cx={8} cy={8} r={8} />
      <path d="m5 5 6 6M11 5l-6 6" />
    </g>
  </svg>
);

export default SvgClear;

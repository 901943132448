import * as React from "react";
import { SVGProps } from "react";

const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.25a.75.75 0 0 0-.75.75v16c0 .414.336.75.75.75h16a.75.75 0 0 0 .75-.75v-8a.75.75 0 0 0-1.5 0v7.25H4.75V4.75H12a.75.75 0 0 0 0-1.5H4Zm12 0a.75.75 0 0 0 0 1.5h2.19l-6.72 6.72a.75.75 0 1 0 1.06 1.06l6.72-6.72V8a.75.75 0 0 0 1.5 0V4a.747.747 0 0 0-.75-.75h-4Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default SvgExternalLink;

import { css } from "styled-components";

export const Label = css<{ active?: boolean; disabled?: boolean }>`
  pointer-events: none;
  transform: translateY(18px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => theme.palette.white.main};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 1;
  ${({ active, disabled }) => !disabled && active && `transform: translateY(8px) scale(0.77);`};
  ${({ disabled }) => disabled && `opacity: 0.35;`}
`;

export const Input = css<{ active?: boolean; disabled?: boolean }>`
  padding: 4px 24px 10px 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.white.main};
  ${({ disabled }) => disabled && `cursor: not-allowed !important;`}

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Container = css<{ error?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.light}`};
  border-color: ${({ theme, error }) => error && theme.palette.coral.main};
  border-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 56px;
  height: 56px;
  outline: none;
  position: relative;

  ${({ disabled }) => disabled && `cursor: not-allowed !important;`}

  &:hover,
  &:focus-within {
    border-color: ${({ theme, error, disabled }) => !error && !disabled && theme.palette.white.main};
  }

  &:focus-visible {
    content: "";
    box-sizing: border-box;
    outline: 3px solid ${({ theme }) => theme.palette.white.main} !important;
    border: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-height: 48px;
    height: 48px;
  }
`;

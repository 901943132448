import React, { PropsWithChildren } from 'react'

import { H3 } from '../Typography/Heading'
import { Body } from '../Typography/Text'
import { _AccordionItem, AccordionContent, AccordionDetails, AccordionHeader, Bold, Icon, Wrapper } from './components'

type ListItemProps = {
  title: string
  content?: string
  body?: React.ReactNode
  bold?: boolean
}

export interface AccordionProps extends SC {
  list: ListItemProps[]
  icon?: React.ReactNode
  Heading?: React.ComponentType
}

type AccordionItemProps = {
  item: ListItemProps
  Heading: React.ComponentType<PropsWithChildren>
  icon: React.ReactNode
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  item: { title, content, body, bold },
  Heading,
  icon,
}: AccordionItemProps) => {
  const [expanded, setExpanded] = React.useState(false)
  const heading = bold ? <Bold>{title}</Bold> : `${title}`

  return (
    <_AccordionItem>
      <AccordionHeader onClick={() => setExpanded(!expanded)}>
        <Heading>{heading}</Heading>
        {icon && <Icon expanded={expanded}>{icon}</Icon>}
      </AccordionHeader>
      <AccordionDetails expanded={expanded}>
        <AccordionContent>
          {content && <Body>{content}</Body>}
          {body}
        </AccordionContent>
      </AccordionDetails>
    </_AccordionItem>
  )
}

const Accordion: React.FC<AccordionProps> = ({ list, icon, Heading, className }) => (
  <Wrapper className={className}>
    {list.map((item) => {
      return <AccordionItem item={item} Heading={Heading || H3} icon={icon} key={item.title} />
    })}
  </Wrapper>
)

export default Accordion

import { css } from 'styled-components'

import { Alignment } from '../../index'

export const CheckboxContainer = css<{ tint?: Theme; align?: Alignment; disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-block: 16px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.white.main};
  color: ${({ disabled, theme }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const StyledCheckbox = css<{ tint?: Theme; align?: Alignment; disabled?: boolean }>`
  background-color: ${({ disabled }) => !disabled && 'transparent'};
  background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  border: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.light}`};
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-right: 16px;

  &:focus-visible {
    content: '';
    border: 2px solid ${({ theme }) => theme.palette.white.main} !important;
    border-radius: 3px;
    ${({ align }) => align === 'left' && `border-top-left-radius: 0; border-bottom-left-radius: 0;`}
  }

  &:focus {
    outline: none;
  }
`

export const Input = css<{ tint?: Theme; disabled?: boolean }>`
  display: none;

  &:checked {
    pointer-events: none;
  }
`

import styled from "styled-components";

export const Range = styled.input.attrs({ type: "range" })<{ value: number; max: number }>`
  -webkit-appearance: none;
  appearance: none;
  padding: 8px;
  height: 8px;
  width: 100%;
  background: transparent;
  border-radius: 0.5rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  /** chrome, safari, opera, edge chromium */
  &::-webkit-slider-runnable-track {
    border-radius: 0.5rem;
    height: 0.5rem;
    /* custom progress for webkit */
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.palette.mint.main} ${({ value, max }) => Math.floor((value / max) * 100) + "%"},
      ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`} 0%
    );
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  &:disabled::-webkit-slider-runnable-track {
    background: linear-gradient(
      90deg,
      ${({ theme }) => `${theme.palette.white.main}${theme.opacity.light}`}
        ${({ value, max }) => Math.floor((value / max) * 100) + "%"},
      ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`} 0%
    );
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -8px;
    background-color: ${({ theme }) => theme.palette.mint.main};
    border: 1px solid ${({ theme }) => theme.palette.white.main};
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &:hover {
      background-color: ${({ theme }) => theme.palette.mint.tint};
    }
  }

  &:disabled::-webkit-slider-thumb {
    background-color: ${({ theme }) => theme.palette.grey.main};
    outline: none;
    height: 24px;
    width: 24px;
    box-shadow: none;
  }

  &:disabled:active::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    margin-top: -8px;
  }

  &:active::-webkit-slider-thumb {
    margin-top: -6px;
    height: 20px;
    width: 20px;
    box-shadow: none;
  }

  /** Firefox */
  &::-moz-range-track {
    background-color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`};
    border-radius: 0.5rem;
    height: 0.5rem;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-progress {
    background-color: ${({ theme }) => theme.palette.mint.main};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  &:disabled::-moz-range-progress {
    background-color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.light}`};
  }

  &::-moz-range-thumb {
    border: none;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.mint.main};
    border: 1px solid ${({ theme }) => theme.palette.white.main};
    height: 24px;
    width: 24px;

    &:hover {
      background-color: ${({ theme }) => theme.palette.mint.tint};
    }
  }

  &:disabled::-moz-range-thumb {
    background-color: ${({ theme }) => theme.palette.grey.main};
    outline: none;
  }

  &:active::-moz-range-thumb {
    height: 20px;
    width: 20px;
    outline: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

export const Label = styled.span<{ selected: boolean }>`
  position: relative;
  margin: 0;
  max-width: 120px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white.main};
  font-weight: ${({ theme, selected }) => (selected ? theme.fontWeight.medium : theme.fontWeight.regular)};

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

export const TickMarks = styled.ul`
  padding: 0 16px;
  list-style: none;
  display: flex;
  justify-content: space-between;
`;

export const TickMark = styled.li<{ isCurrent: boolean; lessThanCurrent: boolean }>`
  position: relative;
  float: left;
  text-align: center;
  cursor: pointer;
  z-index: ${({ isCurrent }) => isCurrent && -12};

  &::before {
    position: absolute;
    top: -28px;
    right: 0;
    left: 0;
    content: "";
    margin: 0 auto;
    width: 4px;
    height: 4px;
    background: ${({ theme, lessThanCurrent }) =>
      lessThanCurrent ? theme.palette.white.main : theme.palette.grey.main};
    border-radius: 50%;
  }
`;

import { css } from 'styled-components'

import { Alignment } from '../../index'

export const SegmentContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const Toggle = css`
  flex: 1;
`

export const SegmentLabel = css<{ tint: Theme; align: Alignment; disabled?: boolean }>`
  max-height: 56px;
  min-width: 50px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 20px 0px 18px 0px;
  color: ${({ theme }) => theme.palette.white.main};
  color: ${({ disabled, theme }) => disabled && `${theme.palette.white.main}${theme.opacity.half}`};
  background-color: ${({ disabled }) => !disabled && 'transparent'};
  background-color: ${({ theme, disabled }) => disabled && `${theme.palette.white.main}${theme.opacity.dark}`};
  transition: linear 0.1s background-color;
  border: 1px solid ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}

  &:hover {
    transition: linear 0.1s border-color;
    border-color: ${({ theme, disabled }) => !disabled && theme.palette.white.main};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    content: '';
    box-sizing: border-box;
    line-height: 14px;
    border: 3px solid ${({ theme }) => theme.palette.white.main} !important;
    ${({ align }) => align === 'left' && `border-top-left-radius: 0; border-bottom-left-radius: 0;`}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`

export const Input = css<{ tint: Theme; disabled?: boolean }>`
  display: none;
`

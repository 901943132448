import { css } from "styled-components";

export const Input = css`
  display: none;
`;

export const Title = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black.main};
  pointer-events: none;
  padding-bottom: 8px;
`;

export const Header = css`
  width: 100%;
  outline: none;
  position: relative;
  padding: 30px 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const CollapsibleContent = css`
  max-height: 0px;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGrey.main};
  transition: all 0.5s linear;
`;

export const InnerContent = css`
  padding: 56px 48px 48px 48px;
`;

export const IconSection = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;

  text-transform: uppercase;
  font-size: 16px;

  path {
    stroke: ${({ theme }) => theme.palette.white.main};
  }
`;

export const Icon = css<{ expanded: boolean }>`
  width: 24px;
  height: 24px;

  transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
  transition: all 0.5s ease-in-out;
`;

import { css } from "styled-components";

export const Wrapper = css`
  padding: 48px;
  color: ${({ theme }) => theme.palette.white.main};
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => `${theme.palette.white.main}${theme.opacity.dark}`};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 40px 24px;
  }
`;

import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { convertTint } from 'ui/utils/theme'

import { IconWrapper as _IconWrapper, Link as _Link } from './styles'

const Link = styled.button`
  ${_Link}
`

const IconWrapper = styled.div`
  ${_IconWrapper}

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
`

export interface LinkProps extends React.ComponentPropsWithoutRef<'button'>, SC {
  tint?: Tint
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
}

const LinkButton: React.FC<LinkProps> = ({
  tint = 'mint',
  iconPosition = 'right',
  icon,
  children,
  ...props
}: LinkProps) => {
  const color = convertTint(tint)
  return (
    <Link tint={color} {...props}>
      {icon && iconPosition === 'left' && <IconWrapper>{icon}</IconWrapper>}
      <span>{children}</span>
      {icon && iconPosition === 'right' && <IconWrapper>{icon}</IconWrapper>}
    </Link>
  )
}

export default LinkButton

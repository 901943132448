import { css } from "styled-components";

const H3 = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.white.main};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export default H3;

import { css } from 'styled-components'
import { Alignment } from '..'
import { Button as PrimaryButton } from '../Primary/styles'

export const Button = css<{ tint: Theme; align: Alignment; disabled?: boolean }>`
  ${PrimaryButton}
  background-color: transparent;
  border: none;
  color: ${({ tint }) => tint.main};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 12px;

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ tint }) => tint.tint};
  }

  &:disabled {
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  }
`
